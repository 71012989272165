/* eslint-disable */

import {NimaRectangle, level1} from './services/nimaRectangle';
import {
    ChangeOrder,
    Clear,
    ColorModifier,
    commandSet, FontModifier, FontSizeModifier, Lock,
    move, PageModifier, PropertyModifier, Remove, Replace, Reposition,
    resize, Rotate,
    SizeModifier,
    StickyNodeModifier, TextModifier,
    undo
} from './services/commands';
import {NimaStickyNote} from './services/nimaStickyNote';
import {NimaFrame} from "./services/nimaFrame";
import {NimaCircle} from "./services/nimaCircle";
import {NimaImage} from "./services/nimaImage";
import {NimaPen} from "./services/nimaPen";
import {NimaLine} from "./services/nimaLine";
import {NimaText} from "./services/nimaText";
import {NimaComposite} from "./services/nimaComposite";
import {NimaComment} from "./services/nimaComment";

class QC extends Array {
    constructor(e) {
        super(),
        this.orderingRules = e
    }
    static for() {
        for (var e = new QC([{
                matches: e=>!0,
                apply: (e,t)=>t.addLast(e)
            }]), t = arguments.length, n = new Array(t), o = 0; o < t; o++)
            n[o] = arguments[o];
        return n && n.forEach((t=>e.add(t))),
        e
    }
    add(e) {
        var t = this.orderingRules.filter((t=>t.matches(e)));
        t.length > 0 && t[t.length - 1].apply(e, this)
    }
    addFirst(e) {
        this.unshift(e)
    }
    addLast(e) {
        this.push(e)
    }
    addAfter(e, t) {
        this.splice(e + 1, 0, t)
    }
    addBefore(e, t) {
        this.splice(e, 0, t)
    }
    findLast(e) {
        for (var t = this.length; t--; )
            if (this[t].cmd === e)
                return t;
        return -1
    }
    findFirstBottom(e) {
        if (0 === this.length)
            return -1;
        var t = this.length - 1;
        if (!this[t].cmd === e)
            return -1;
        for (; t >= 0 && this[t].cmd === e; )
            t--;
        return t + 1
    }
    findLastTopFixed() {
        if (0 === this.length)
            return -1;
        if (!this[0].isFixedPosition())
            return -1;
        for (var e = 0; e < this.length && this[e].isFixedPosition(); )
            e++;
        return e - 1
    }
    findFirstBottomFixed() {
        if (0 === this.length)
            return -1;
        var e = this.length - 1;
        if (!this[e].isFixedPosition())
            return -1;
        for (; e >= 0 && this[e].isFixedPosition(); )
            e--;
        return e + 1
    }
    clear() {
        this.splice(0, this.length)
    }
}

/**
 *
 */
function pE() {
    var e = []
        , t = []
        , n = [];
    for (var o of this._commands) {
        try {
            o.status = 'normal'
        } catch (e) {
            throw e;
        }
        'undo' === o.cmd ? 'redo' === o.type ? t.push(o) : e.push(o) : n.push(o)
    }
    return {
        undos: e,
        redos: t,
        others: n
    }
}
const L = (e,t,n)=>{
    Object.defineProperty(e, t, {
        configurable: !0,
        enumerable: !1,
        value: n
    })
}
/**
 * @param e
 */
function markRaw(e) {
    return L(e, '__v_skip', !0),
    e
}

const ck = class {
    constructor(e) {
        this.factory = e
    }
    execute(e) {
        var t = this.factory(e.command);
        e.add(t)
    }
}

class CommandRegistery {
    constructor() {
        this.services = new Map
        this.deserializerInfo = {
            stickyNote: e=>Object.assign(new NimaStickyNote, JSON.parse(JSON.stringify(e))),
            frame: e=>Object.assign(new NimaFrame, JSON.parse(JSON.stringify(e))),
            circle: e=>{
                var t = Object.assign(new NimaCircle, JSON.parse(JSON.stringify(e)));
                return t.upgradeFromVer1(),
                    t
            },
            image: e=>Object.assign(new NimaImage, JSON.parse(JSON.stringify(e))),
            line: e=>Object.assign(new NimaLine, JSON.parse(JSON.stringify(e))),
            pen: e=>Object.assign(new NimaPen, JSON.parse(JSON.stringify(e))),
            rectangle: e=>{
                var t = Object.assign(new NimaRectangle, JSON.parse(JSON.stringify(e)));
                return t.upgradeFromVer1(),
                t
            },
            text: e=>Object.assign(new NimaText, JSON.parse(JSON.stringify(e))),
            comment: e=>Object.assign(new NimaComment, JSON.parse(JSON.stringify(e))),
            composite: e=>{
                var t = Object.assign(new NimaComposite, JSON.parse(JSON.stringify(e)));
                return t.shapes = t.shapes.map((e=>this.deserializerInfo[e.cmd](e))),
                    t
            },
            // link: e=>{
            //     var t = Object.assign(new Jk, JSON.parse(JSON.stringify(e)));
            //     return t.shapes = t.shapes.map((e=>this.deserializerInfo[e.cmd](e))),
            //         t
            // }
        };
        var e = e=>e.length > 1 ? 's' : '';
        this.register('move', {
            handler: new move,
            formatter: t=>'moved '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('undo', {
            handler: new undo,
            formatter: e => 'redo' === e.type ? 'redo a command' : 'undo a command'
        })
        this.register('resize', {
            handler: new resize,
            formatter: t=>'resized '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('commandSet', {
            handler: new commandSet,
            formatter: e=>'changed shapes'
        })
        this.register('stickyNoteModifier', {
            handler: new StickyNodeModifier,
            formatter: e=>'changed a sticky note to "'.concat(e.text, '"')
        })
        this.register('sizeModifier', {
            handler: new SizeModifier,
            formatter: t => 'resized '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('colorModifier', {
            handler: new ColorModifier,
            formatter: t=>'change the color of '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('clear', {
            handler: new Clear,
            formatter: e=>'cleared the board'
        })
        this.register('remove', {
            handler: new Remove,
            formatter: t=>'removed '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('textModifier', {
            handler: new TextModifier,
            formatter: e=>'changed text to "'.concat(e.value, '"')
        })
        this.register('fontModifier', {
            handler: new FontModifier,
            formatter: t=>'changed font of '.concat(t.shapes.length, ' text').concat(e(t.shapes))
        })
        this.register('fontSizeModifier', {
            handler: new FontSizeModifier,
            formatter: t=>'changed font size of '.concat(t.shapes.length, ' text').concat(e(t.shapes))
        })
        this.register('pageModifier', {
            handler: new PageModifier,
            formatter: e=>'changed the page to '.concat(e.page)
        })
        this.register('propertyModifier', {
            handler: new PropertyModifier,
            formatter: t=>'modified '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('replace', {
            handler: new Replace,
            formatter: e=>'replaced a shape'
        })
        this.register('reposition', {
            handler: new Reposition,
            formatter: e=>'changed a shape'
        })
        this.register('lock', {
            handler: new Lock,
            formatter: t=>'locked '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('changeOrder', {
            handler: new ChangeOrder,
            formatter: e=>'changed order of shapes'
        })
        this.register('rotate', {
            handler: new Rotate,
            formatter: t=>'rotated '.concat(t.shapes.length, ' shape').concat(e(t.shapes))
        })
        this.register('rectangle', {
            handler: new ck(this.deserializerInfo.rectangle),
            formatter: e=>'drew a rectangle'
        })
        this.register('stickyNote', {
            handler: new ck(this.deserializerInfo.stickyNote),
            formatter: e=>'added a sticky note "'.concat(e.text, '"')
        })

        this.register("frame", {
            handler: new ck(this.deserializerInfo.frame),
            formatter: e=>"added a frame"
        })
        this.register("circle", {
            handler: new ck(this.deserializerInfo.circle),
            formatter: e=>"drew a circle"
        })

        this.register("image", {
            handler: new ck(this.deserializerInfo.image),
            formatter: e=>"uploaded an image"
        })
        
        this.register("line", {
            handler: new ck(this.deserializerInfo.line),
            formatter: e=>"arrow" === e.type ? "drew a arrow" : "drew a line"
        })
        
        this.register("pen", {
            handler: new ck(this.deserializerInfo.pen),
            formatter: e=>"drew with pen"
        })
        this.register("text", {
            handler: new ck(this.deserializerInfo.text),
            formatter: e=>"added a text"
        })
        this.register("composite", {
            handler: new ck(this.deserializerInfo.composite),
            formatter: e=>"added a shape"
        })
        this.register("comment", {
            handler: new ck(this.deserializerInfo.comment),
            formatter: e=>"added a comment"
        })
        // this.register("link", {
        //     handler: new ck(this.deserializerInfo.link),
        //     formatter: e=>"added a link"
        // })
    }
    register(e, t) {
        if (this.services.has(e))
            throw new Error('Service already registered for that '.concat(e, '.'));
        this.services.set(e, t)
    }
    resolve(e) {
        var t = this.services.get(e);
        if (!t)
            throw new Error('a service with the name '.concat(e, ' not found'));
        return t
    }
}
export class DrawingQueue {
    constructor(e, n) {
        Object.defineProperty(this, 'shapes', {
            enumerable: !0,
            configurable: !1,
            writable: !1,
            value: (0,
            markRaw)(new QC(DrawingQueue.orderingRules))
        })
        //     this.background = n || new nk,
        //     this.queue = (0,
        //         XC.c)(1),
        //     this.context = e,
        //     this.filters = new Map
    }
    static get orderingRules() {
        return [{
            matches: e=>!0,
            apply: (e,t)=>{
                var n = t.findFirstBottom('comment');
                -1 === n ? t.addLast(e) : t.addBefore(n, e)
            }
        }, {
            matches: e=>'frame' === e.cmd,
            apply: (e,t)=>{
                var n = t.findLast('frame');
                -1 === n ? t.addFirst(e) : t.addAfter(n, e)
            }
        }, {
            matches: e=>'comment' === e.cmd,
            apply: (e,t)=>t.addLast(e)
        }]
    }
    addFilter(e, t) {
        this.filters.set(e, t)
    }
    removeFilter(e) {
        this.filters.delete(e)
    }
    getFilters() {
        return Array.from(this.filters.values())
    }
    add(e) {
        this.shapes.add(e)
    }
    clear() {
        this.shapes.clear()
    }
    empty() {
        this.shapes.clear()
    }
    clearCanvas(e) {
    }
    hasConnector(e, t) {
        for (var n of this.shapes)
            if (n.endpoints && !(n.endpoints.start != e.id && n.endpoints.start != t.id || n.endpoints.end != e.id && n.endpoints.end != t.id))
                return !0;
        return !1
    }
    findConnectors(e) {
        var t = []
            , n = function(n) {
                n.endpoints && (e.some((e=>n.endpoints.start === e.id)) || e.some((e=>n.endpoints.end === e.id))) && t.push(n)
            };
        for (var o of this.shapes)
            n(o);
        return t
    }
    draw(e) {
       
    }
    drawAll(e) {
    }
}

export class NimaThirdPartyImporter {
    constructor() {
        this._commands = []
        this.commandRegistry = new CommandRegistery(),
        this.drawingQueue = new DrawingQueue(),
        this.dispatcher = {
            dispatch: (e,t)=>{
                this.commandRegistry.resolve(e).handler.execute(t)
            }
        }
    }
    
    invoke(e) {
        let t = true;
        let o = {
            changeContext: []
        };
        try {
            var a = []
                , r = !1
                , i = Array.isArray(e) ? e : [e];
            if (0 === i.length)
                return Promise.resolve(o);
            var s = !1;
            for (var l of i)
                this._commands.push(l),
                'undo' === l.cmd && (s = !0);
            var {undos: c, redos: d, others: p} = function(e, t, n) {
                    return n
                }(this, new WeakSet(), pE).call(this)
                , h = new Set(i.map((e=>e.id)));
            if (s) {
                this.drawingQueue.empty();
                var u = t ? ()=>!1 : e=>h.has(e.id);
                this._process(d, a, o, u),
                this._process(c, a, o, u),
                this._process(p, a, o, u),
                r = !0
            } else
                r = this._process(i, a, o, t ? ()=>!1 : ()=>!0);
            return new Promise(((e,t)=>{
                e(true)
                // r ? (this.drawingQueue.clearCanvas(),
                // this.drawingQueue.drawAll().then((()=>e(o))).catch((e=>t(e)))) : this.drawingQueue.draw(a).then((()=>e(o))).catch((e=>t(e)))
            }
            ))
        } catch (e) {
            console.error(e)
            return Promise.reject('An error occurred while processing the command')
        }
    }
    _buildProxy(e, t) {
        const cE = Symbol('isProxy');
        var n = {
            [cE]: {
                isChanged: !1
            },
            changed: ()=>{
                n[cE].isChanged || (n[cE].isChanged = !0,
                t(e))
            }
            ,
            get: (e,t)=>{
                var o = Reflect.get(e, t);
                return o && (o instanceof level1 || t == cE || Array.isArray(o)) ? this._buildProxy(o, (()=>n.changed())) : o
            }
            ,
            set: (e,t,o)=>{
                var a = Reflect.set(e, t, o);
                return n.changed(),
                a
            }
        };
        return new Proxy(e,n)
    }
    _process(e, n, o, a) {
        var r = this
            , i = !1
            , s = (e,t)=>{
                e.modifiedBy = t.uid,
                e.modifiedTimestamp = t.timestamp
            }
            , l = function(e) {
                var l = (n,r)=>{
                    o.changeContext.push({
                        type: n,
                        shape: r
                    })
                    // a(e) && (((0,
                    //     t.isRef)(r) || (0,
                    //     t.isReactive)(r) || (0,
                    //     t.isReadonly)(r)) && console.log("commands", "command is reactive or proxy. command:".concat(r, " stack:").concat((new Error).stack)))
                }
            ;
                if ('disabled' === e.status)
                    return 1;
                var c = r
                    , d = {
                        command: e,
                        getCommand: e=>r._commands.find((t=>t.id === e)),
                        disableCommand(t) {
                            i = !0,
                            t.status = 'disabled';
                            var n = 'redo' === e.type ? 'redo' : 'undo';
                            l(n, t)
                        },
                        get: t=>{
                            i = !0;
                            var n = r.drawingQueue.shapes.find((e=>e.id === t));
                            if (n)
                                return r._buildProxy(n, (()=>{
                                    s(n, e),
                                    l('modified', n)
                                }
                                ))
                        }
                        ,
                        getIndex: e=>r.drawingQueue.shapes.findIndex((t=>t.id === e)),
                        remove: e=>{
                            if (e >= 0) {
                                i = !0;
                                var t = r.drawingQueue.shapes.splice(e, 1);
                                l('deleted', t[0])
                            }
                        }
                        ,
                        invoke(e) {
                            var t = d.command;
                            d.command = e,
                            c.dispatcher.dispatch(e.cmd, d),
                            d.command = t
                        },
                        swap: (t,n)=>{
                            var o = r.drawingQueue.shapes.findIndex((e=>e.id === t));
                            if (!(o < 0)) {
                                var a = r.drawingQueue.shapes[o];
                                if (!a.isFixedPosition()) {
                                    if (r.drawingQueue.shapes.splice(o, 1),
                                    'up' === n) {
                                        var l = r.drawingQueue.shapes.findFirstBottomFixed();
                                        -1 === l ? r.drawingQueue.shapes.addLast(a) : r.drawingQueue.shapes.addBefore(l, a)
                                    } else {
                                        var c = r.drawingQueue.shapes.findLastTopFixed();
                                        -1 === c ? r.drawingQueue.shapes.addFirst(a) : r.drawingQueue.shapes.addAfter(c, a)
                                    }
                                    s(a, e),
                                    i = !0
                                }
                            }
                        }
                        ,
                        disableAllCommands: ()=>{
                            i = !0,
                            r.drawingQueue.clear();
                            var e = ''
                                , t = r._commands.slice().reverse().find((e=>'clear' === e.cmd && 'disabled' !== e.status));
                            for (var n of (void 0 !== t && (e = t.id),
                            r._commands)) {
                                if (n.id === e)
                                    break;
                                n.status = 'disabled'
                            }
                            l('cleared', {})
                        }
                        ,
                        add: e=>{
                            n.push(e),
                            r.drawingQueue.add(e),
                            i = !0,
                            l('added', e)
                        }
                        ,
                        insert: (e,t)=>{
                            var n = null
                                , o = {
                                    command: t,
                                    add: e=>{
                                        n = e
                                    }
                                };
                            r.dispatcher.dispatch(t.cmd, o),
                            i = !0,
                            r.drawingQueue.shapes.splice(e, 0, n),
                            l('added', n)
                        }
                    };
                r.dispatcher.dispatch(e.cmd, d)
            };
        for (var c of e) {
            l(c);
        }
        return i
    }
}

export class NimaThirdPartyImporter2 {
    constructor() {
        this._commands = []
        this.commandRegistry = new CommandRegistery(),
            this.drawingQueue = new DrawingQueue(),
            this.dispatcher = {
                dispatch: (e,t)=>{
                    this.commandRegistry.resolve(e).handler.execute(t)
                }
            }
    }

    async invoke(e) {
        let t = true;
        let o = {
            changeContext: []
        };
        try {
            var a = []
            var r = !1
            var i = Array.isArray(e) ? e : [e];

            if (0 === i.length) {
                return Promise.resolve(o);
            }

            var s = !1;
            const batchSize = 50;

            for (let startIdx = 0; startIdx < i.length; startIdx += batchSize) {
                const endIdx = Math.min(startIdx + batchSize, i.length);
                const currentBatch = i.slice(startIdx, endIdx);

                await new Promise(resolve => {
                    setTimeout(() => {
                        for (var l of currentBatch) {
                            this._commands.push(l),
                            'undo' === l.cmd && (s = !0);
                        }
                        resolve();
                    }, 0);
                });
            }

            var {undos: c, redos: d, others: p} = function(e, t, n) {
                return n
            }(this, new WeakSet(), pE).call(this);

            var h = new Set(i.map((e=>e.id)));

            if (s) {
                this.drawingQueue.empty();
                var u = t ? ()=>!1 : e=>h.has(e.id);
                await this._processBatched(d, a, o, u);
                await this._processBatched(c, a, o, u);
                await this._processBatched(p, a, o, u);
                r = !0;
            } else {
                r = await this._processBatched(i, a, o, t ? ()=>!1 : ()=>!0);
            }

            return Promise.resolve(o);
        } catch (e) {
            console.error(e);
            return Promise.reject('An error occurred while processing the command');
        }
    }

    async _processBatched(e, n, o, a) {
        var r = this
        var i = !1
        var s = (e,t)=>{
            e.modifiedBy = t.uid,
                e.modifiedTimestamp = t.timestamp
        }
        var l = function(e) {
            var l = (n,r)=>{
                o.changeContext.push({
                    type: n,
                    shape: r
                })
            }

            if ('disabled' === e.status)
                return 1;

            var c = r
            var d = {
                command: e,
                getCommand: e=>r._commands.find((t=>t.id === e)),
                disableCommand(t) {
                    i = !0,
                        t.status = 'disabled';
                    var n = 'redo' === e.type ? 'redo' : 'undo';
                    l(n, t)
                },
                get: t=>{
                    i = !0;
                    var n = r.drawingQueue.shapes.find((e=>e.id === t));
                    if (n)
                        return r._buildProxy(n, (()=>{
                            s(n, e),
                                l('modified', n)
                        }))
                },
                getIndex: e=>r.drawingQueue.shapes.findIndex((t=>t.id === e)),
                remove: e=>{
                    if (e >= 0) {
                        i = !0;
                        var t = r.drawingQueue.shapes.splice(e, 1);
                        l('deleted', t[0])
                    }
                },
                invoke(e) {
                    var t = d.command;
                    d.command = e,
                        c.dispatcher.dispatch(e.cmd, d),
                        d.command = t
                },
                swap: (t,n)=>{
                    var o = r.drawingQueue.shapes.findIndex((e=>e.id === t));
                    if (!(o < 0)) {
                        var a = r.drawingQueue.shapes[o];
                        if (!a.isFixedPosition()) {
                            if (r.drawingQueue.shapes.splice(o, 1),
                            'up' === n) {
                                var l = r.drawingQueue.shapes.findFirstBottomFixed();
                                -1 === l ? r.drawingQueue.shapes.addLast(a) : r.drawingQueue.shapes.addBefore(l, a)
                            } else {
                                var c = r.drawingQueue.shapes.findLastTopFixed();
                                -1 === c ? r.drawingQueue.shapes.addFirst(a) : r.drawingQueue.shapes.addAfter(c, a)
                            }
                            s(a, e),
                                i = !0
                        }
                    }
                },
                disableAllCommands: ()=>{
                    i = !0,
                        r.drawingQueue.clear();
                    var e = ''
                    var t = r._commands.slice().reverse().find((e=>'clear' === e.cmd && 'disabled' !== e.status));
                    for (var n of (void 0 !== t && (e = t.id),
                        r._commands)) {
                        if (n.id === e)
                            break;
                        n.status = 'disabled'
                    }
                    l('cleared', {})
                },
                add: e=>{
                    n.push(e),
                        r.drawingQueue.add(e),
                        i = !0,
                        l('added', e)
                },
                insert: (e,t)=>{
                    var n = null
                    var o = {
                        command: t,
                        add: e=>{
                            n = e
                        }
                    };
                    r.dispatcher.dispatch(t.cmd, o),
                        i = !0,
                        r.drawingQueue.shapes.splice(e, 0, n),
                        l('added', n)
                }
            };
            r.dispatcher.dispatch(e.cmd, d)
        };

        const batchSize = 200;
        for (let startIdx = 0; startIdx < e.length; startIdx += batchSize) {
            const endIdx = Math.min(startIdx + batchSize, e.length);
            const currentBatch = e.slice(startIdx, endIdx);

            await new Promise(resolve => {
                setTimeout(() => {
                    for (var command of currentBatch) {
                        l(command);
                    }
                    resolve();
                }, 0);
            });
        }

        return i;
    }

    _buildProxy(e, t) {
        const cE = Symbol('isProxy');
        var n = {
            [cE]: {
                isChanged: !1
            },
            changed: ()=>{
                n[cE].isChanged || (n[cE].isChanged = !0,
                    t(e))
            },
            get: (e,t)=>{
                var o = Reflect.get(e, t);
                return o && (o instanceof level1 || t == cE || Array.isArray(o)) ? this._buildProxy(o, (()=>n.changed())) : o
            },
            set: (e,t,o)=>{
                var a = Reflect.set(e, t, o);
                return n.changed(),
                    a
            }
        };
        return new Proxy(e,n)
    }
}