import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Modal from '../../modal/Modal';
import eventEmitter from '../../../helpers/EventEmitter';
import {
    EMITTER_TYPES,
    FLOWCHART_STATUS,
    PAGE_ITEM_DROPDOWN_MENU,
    PAGE_SOCKET_EVENTS,
    SOCKET_STATUS_MODS,
    NIMA_IMPORT_STATUS
} from '../../../helpers/Constant';
import Dropdown from '../../dropdown/Dropdown';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';
import getToastIcon from '../../../helpers/media/GetToastIcon';

import BuilderLogo from '../../../assets/images/Builder_B@2x.png';
import MoreIcon from '../../../assets/images/more.svg';
import PencilIcon from '../../../assets/images/pencil2.svg';
import TrashIcon from '../../../assets/images/trash2.svg';
import CopyIcon from '../../../assets/images/copy.svg';
import PlusIcon from '../../../assets/images/plus-icon.svg';
import { isMainPage } from '../../../helpers/pages/CommonPagesMethods';
import store from '../../../redux/Store';
import TooltipWrapper from '../../tooltip/TooltipWrapper';
import InfoCircle from '../../svgIcons/InfoCircle';

const DROPDOWN_ICONS = [
    { src: PencilIcon, width: 15, height: 15 },
    { src: TrashIcon, width: 14, height: 14 },
    { src: CopyIcon, width: 15, height: 15 },
];

const DrawerPagesContent = ({ socketRef, canvas }) => {
    const inputRef = useRef();
    const modalRef = useRef();
    const deletedPageInfo = useRef({});
    const dispatch = useDispatch();

    const userPermission = useSelector((state) => state.user.permission);
    const pages = useSelector((state) => state.rightDrawer.pages);
    const activePageSlugId = useSelector(
        (state) => state.rightDrawer.activePage?.slugId
    );
    const socketStatus = useSelector((state) => state.socket.status);
    const migrationReport = useSelector((state) => state?.board?.migrationData?.nimaImportReport);

    const [editFor, setEditFor] = useState('');
    const [dropdownVisibility, setDropdownVisibility] = useState(false);

    const isDropdownMenuItemDisabled = (menuItem) => {
        if (
            (menuItem.id === 'delete' && pages.length === 1) ||
      (menuItem.id === 'delete' &&
        !isUserHasAccessToFeature('page_delete', userPermission)) ||
      (menuItem.id !== 'edit' &&
        !isUserHasAccessToFeature('edit_page', userPermission)) ||
      (menuItem.id !== 'copy' &&
        !isUserHasAccessToFeature('page_duplicate', userPermission))
        ) {
            return true;
        }

        return false;
    };

    const changePage = (pageId) => {
        if (pageId === activePageSlugId) return;

        dispatch({
            type: 'rightDrawer/changePage',
            payload: pageId,
        });
    };

    const createNewPage = () => {
        socketRef.current.emit(PAGE_SOCKET_EVENTS.PAGE_CREATED, {}, (response) => {
            if (response?.status !== 'ok') return;

            const pageName = response.emitData.pageName;

            dispatch({
                type: 'rightDrawer/createNewPage',
                payload: {
                    id: response.emitData.id,
                    pageName,
                    wbPageId: response.emitData.wbPageId,
                },
            });

            toast.success(`"${pageName}" added.`, {
                icon: getToastIcon('success'),
                className: 'wb_toast',
            });
        });
    };

    const onMenuItemClicked = ({ id, pageId, pageName }) => {
        if (id === 'edit') {
            setEditFor(pageId);
        } else if (id === 'delete') {
            deletedPageInfo.current = { pageId, pageName };
            modalRef.current?.openModal();
        } else if (id === 'copy') {
            const infoToast = toast.info(`Copying "${pageName}".`, {
                autoClose: false,
                icon: getToastIcon('info'),
                className: 'wb_toast'
            });

            socketRef.current.emit(
                PAGE_SOCKET_EVENTS.PAGE_DUPLICATED,
                {
                    wbPageId: pageId,
                },
                (response) => {
                    const copiedPageName = response.emitData.pageName;

                    dispatch({
                        type: 'rightDrawer/createNewPage',
                        payload: {
                            id: response.emitData.id,
                            pageName: copiedPageName,
                            wbPageId: response.emitData.wbPageId,
                        },
                    });

                    toast.update(infoToast, {
                        type: 'success',
                        render: `"${pageName}" copied as "${copiedPageName}".`,
                        autoClose: 3000,
                        icon: getToastIcon('success'),
                    });
                }
            );
        }
    };

    const onPageNameChanged = (pageId) => {
        let val = inputRef.current?.value;
        //exclude page itself
        const pageNames = pages.filter(p => p.wbPageId !== pageId).map((page) => page.pageName.toLowerCase());

        if (pageNames.includes(val.trim().toLowerCase())) {
            toast.error(`Error: '${val.trim()}' name is already taken for Page. Please choose a unique name.`, {
                icon: getToastIcon('error'),
                className: 'wb_toast',
            });
            setEditFor('');
            return
        }

        if (!val || (val && !val.trim())) {
            setEditFor('');
            return;
        }

        // Remove multiple spaces
        val = val.replace(/\s+/g, ' ');
        if (!val) {
            setEditFor('');
            return;
        }

        socketRef.current.emit(
            PAGE_SOCKET_EVENTS.PAGE_NAME_CHANGED,
            {
                pageName: val,
                wbPageId: pageId,
            },
            (response) => {
                if (!response || response?.status !== 'ok') return;

                dispatch({
                    type: 'rightDrawer/changePageName',
                    payload: { wbPageId: pageId, pageName: val },
                });

                setEditFor('');
            }
        );
    };

    /**
     * Handles flowchart status updating on deleting page.
     * @param {string} pageId - WbPageId.
     */
    const handleFlowchartUpdatingOnDeletingPage = (pageId) => {
        try {
            const actualId = pages.find((page) => page.wbPageId === pageId)?.id;
            const isDeletedMainPage = isMainPage(pages, actualId);
            if (!isDeletedMainPage) return;

            const flowchartState = store?.getState()?.board?.flowchartData;
            if (!flowchartState || flowchartState?.status !== FLOWCHART_STATUS.COMPLETED) return;

            // if the deleted page was the main page and 
            // board flowchart status is completed then
            // change flowchart status to removed

            dispatch({
                type: 'board/changeBoardDetails',
                payload: {
                    userFlowDiagram_status: FLOWCHART_STATUS.REMOVED
                }
            })
            socketRef.current.emit('ufd_status', {
                status: FLOWCHART_STATUS.REMOVED
            });

        } catch (err) {
            console.error('Error while handling flowchart status update on deletion', err)
        }
    }

    /**
     * If the deleted page is the page that old whiteboard data is imported in, we need to set nima import status as pending.
     * @param {string} pageId Page id that is created for importing third party board.
     */
    const handleNimaStatusOnDeletingPage = (pageId) => {
        try {
            const page = pages.find(page => page.wbPageId === pageId);
            if (page && (page?.forNima || (page?.properties && page.properties.forNima))) {
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {
                        nimaImportStatus: NIMA_IMPORT_STATUS.PENDING
                    }
                })
                socketRef.current.emit('migration_status', {
                    status: NIMA_IMPORT_STATUS.PENDING
                })
            }
        } catch (err) {
            console.error(err)
        }
    }


    const deletePage = () => {
        const { pageName, pageId: wbPageId } = deletedPageInfo.current;

        socketRef.current.emit(
            PAGE_SOCKET_EVENTS.PAGE_DELETED,
            {
                wbPageId,
                isDeleted: true,
            },
            (response) => {
                if (!response || response?.status !== 'ok') return;

                if (activePageSlugId === wbPageId) {
                    eventEmitter.fire(EMITTER_TYPES.PAGE_CHANGED, {
                        wbPageId,
                    });
                }

                handleFlowchartUpdatingOnDeletingPage(wbPageId)
                handleNimaStatusOnDeletingPage(wbPageId)
                dispatch({
                    type: 'rightDrawer/deletePage',
                    payload: { wbPageId },
                });

                deletedPageInfo.current = {};
                modalRef.current.closeModal();

                toast.success(`"${pageName}" deleted.`, {
                    icon: getToastIcon('success'),
                    className: 'wb_toast',
                });
            }
        );
    };

    const navigateToPage = (pageId) => {
        eventEmitter.fire(EMITTER_TYPES.PAGE_CHANGED, {
            wbPageId: deletedPageInfo.current.pageId,
        });
        changePage(pageId);
    };

    useEffect(() => {
        if (socketStatus !== SOCKET_STATUS_MODS.CONNECTED || !socketRef.current)
            return;

        socketRef.current.on(PAGE_SOCKET_EVENTS.PAGE_CREATED, (data) => {
            dispatch({
                type: 'rightDrawer/createNewPage',
                payload: {
                    id: data.id,
                    pageName: data.pageName,
                    wbPageId: data.wbPageId,
                    properties: data.properties
                },
            });
        });

        socketRef.current.on(PAGE_SOCKET_EVENTS.PAGE_DELETED, (data) => {
            // In case the current active page is deleted.
            if (activePageSlugId === data.wbPageId) {
                // Show error if deleted page includes comment
                const objects = canvas.getObjects();
                if (
                    objects.some((obj) =>
                        ['comment', 'commentRefWrapper'].includes(obj.type)
                    )
                ) {
                    toast.info('Comment deleted', {
                        icon: getToastIcon('info'),
                        className: 'wb_toast',
                    });
                }

                eventEmitter.fire(EMITTER_TYPES.PAGE_CHANGED, {
                    wbPageId: data.wbPageId,
                });
            }

            dispatch({
                type: 'rightDrawer/deletePage',
                payload: { wbPageId: data.wbPageId },
            });
        });

        socketRef.current.on(PAGE_SOCKET_EVENTS.PAGE_DUPLICATED, (data) => {
            dispatch({
                type: 'rightDrawer/createNewPage',
                payload: {
                    id: data.id,
                    pageName: data.pageName,
                    wbPageId: data.wbPageId,
                },
            });
        });

        socketRef.current.on(PAGE_SOCKET_EVENTS.PAGE_NAME_CHANGED, (data) => {
            dispatch({
                type: 'rightDrawer/changePageName',
                payload: { pageName: data.pageName, wbPageId: data.wbPageId },
            });
        });

        return () => {
            socketRef.current.off(PAGE_SOCKET_EVENTS.PAGE_CREATED);
            socketRef.current.off(PAGE_SOCKET_EVENTS.PAGE_DELETED);
            socketRef.current.off(PAGE_SOCKET_EVENTS.PAGE_DUPLICATED);
            socketRef.current.off(PAGE_SOCKET_EVENTS.PAGE_NAME_CHANGED);
        };
    }, [socketStatus, activePageSlugId]);

    useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current.focus();
    }, [editFor]);

    return (
        <>
            <div className="pages">
                <div
                    className={clsx('pageList', {
                        __isDropdownVisible: dropdownVisibility,
                    })}
                    tabIndex={1}
                >
                    {pages.map((page) => (
                        <div
                            key={page.wbPageId}
                            className={clsx('pageItem', {
                                __active: activePageSlugId === page.wbPageId,
                            })}
                        >
                            <div
                                className="thumbnail"
                                onClick={() => navigateToPage(page.wbPageId)}
                            >
                                <img alt="Builder Logo" src={BuilderLogo} />
                            </div>

                            <div className="details">
                                {editFor !== page.wbPageId ? (
                                    <span
                                        className={clsx({ '--hasPageName': !!page.pageName })}
                                        onClick={() => navigateToPage(page.wbPageId)}
                                    >
                                        {page.pageName?.length > 25
                                            ? `${page.pageName.substring(0, 25)}...`
                                            : page.pageName}
                                        { (page?.properties?.forNima || page?.forNima) && migrationReport ? (
                                            <TooltipWrapper
                                                text={
                                                    <ul style={{ minWidth: '164px' }}>
                                                        {
                                                            // comment count
                                                            migrationReport?.numbers?.totalCommentCount >= 0 ? (
                                                                <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span className='text'>Comment migration</span>
                                                                    <span className='value'>{migrationReport?.commentImportPercentage}%</span>
                                                                </li>
                                                            ) : null
                                                        }
                                                        {
                                                            // images count
                                                            migrationReport?.numbers?.totalImageCount >= 0 ? (
                                                                <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span className='text'>Images migration</span>
                                                                    <span className='value'>{migrationReport?.imageImportPercentage}%</span>
                                                                </li>
                                                            ) : null
                                                        }
                                                        {
                                                            // shapes count
                                                            migrationReport?.numbers?.totalShapeCount >= 0 ? (
                                                                <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span className='text'>Elements migration</span>
                                                                    <span className='value'>{migrationReport?.shapeImportPercentage}%</span>
                                                                </li>
                                                            ) : null
                                                        }
                                                    </ul>
                                                }
                                                triggerOffset={15}>
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '4px' }}>
                                                    <InfoCircle width={12} height={12} />
                                                </span>
                                            </TooltipWrapper> 
                                        ) : null}
                                    </span>
                                ) : (
                                    <input
                                        defaultValue={page.pageName}
                                        maxLength={50}
                                        onBlur={() => onPageNameChanged(page.wbPageId)}
                                        placeholder="Page Name"
                                        ref={inputRef}
                                        type="text"
                                    />
                                )}

                                <Dropdown
                                    disabled={
                                        !isUserHasAccessToFeature(
                                            'page_modification',
                                            userPermission
                                        )
                                    }
                                    menu={PAGE_ITEM_DROPDOWN_MENU.map((item, idx) => {
                                        const icon = DROPDOWN_ICONS[idx];

                                        return {
                                            ...item,
                                            icon: (
                                                <img
                                                    alt={page.pageName}
                                                    height={icon.height}
                                                    src={icon.src}
                                                    width={icon.width}
                                                />
                                            ),
                                            pageId: page.wbPageId,
                                            pageName: page.pageName,
                                            disabled: isDropdownMenuItemDisabled(item, pages),
                                        };
                                    })}
                                    onMenuItemClicked={onMenuItemClicked}
                                    onVisibilityChange={setDropdownVisibility}
                                    usePortal={false}
                                >
                                    <img alt="" src={MoreIcon} />
                                </Dropdown>
                            </div>
                        </div>
                    ))}
                </div>

                <button
                    className="addPageBtn"
                    disabled={!isUserHasAccessToFeature('add_page', userPermission)}
                    onClick={createNewPage}
                    type="button"
                >
                    <img alt="Add page" src={PlusIcon} />
                    <span>Add Page</span>
                </button>
            </div>

            <Modal
                actions={[
                    <button
                        type="button"
                        id="pagesCancelBtn"
                        key="pagesCancelBtn"
                        onClick={modalRef.current?.closeModal}
                    >
                        Cancel
                    </button>,
                    <button
                        id="pagesDeleteBtn"
                        onClick={deletePage}
                        type="button"
                        key="pagesDeleteBtn"
                    >
                        Delete
                    </button>,
                ]}
                description="Are you sure you want to delete this page?"
                ref={modalRef}
                title="Delete Page"
            />
        </>
    );
};

DrawerPagesContent.propTypes = {
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    canvas: PropTypes.object
}

export default DrawerPagesContent;
