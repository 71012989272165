import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import HelpButton from './HelpButton';
import { getBrowserName, getDeviceName } from '../../helpers/BrowserInfo';
import { onImageUpload , onDownloadCanvas ,onDownloadSVG} from '../../hooks/UseMedia';
import {
    SEGMENT_ANALYTICS_CONSTANTS,
    EMITTER_TYPES,
    FLOWCHART_MODAL_TYPES,
    FLOWCHART_STATUS,
    MODAL_WINDOWS,
    COMMENT_DRAWER_WIDTH,
    NIMA_IMPORT_STATUS,
    PAGE_SOCKET_EVENTS,
    BOARD_ITEM_DROPDOWN_MENU,
    APP_NAMES, ANIMATION_DURATIONS,
    SOCKET_EVENT
} from '../../helpers/Constant';
import { isExternalProject, isEnterpriseProject, isUserHasAccessToFeature } from '../../helpers/CommonFunctions';
import Invite from '../invite/Invite';
import useOverlay from '../../hooks/UseOverlay';
import { trackAction } from '../../helpers/SegmentEventTracker';
import builderLogo from '../../assets/images/Builder_B@2x.png';
import HeaderSearch from './search/HeaderSearch';
import { USER_ROLES } from '../../helpers/Constant';
import '../bottomBars/bottomBars.scss';
import './Header.scss';
import eventEmitter from '../../helpers/EventEmitter';
import useCanvas from '../../hooks/UseCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { getMainPageWbId, isMainPage } from '../../helpers/pages/CommonPagesMethods';
import { CanvasesContext } from '../../context/canvases/CanvasesContext';
import { debounce } from '../../helpers/OptimizationUtils';
import Modal from '../modal/Modal';
import { removeUserAccess } from '../../services/InviteService';


import CommentIconBubble from '../../assets/images/canvasRedesignImages/comment_bubble.svg';
import CommentIconBubbleUnread from '../../assets/images/canvasRedesignImages/comment_bubble_unread.svg'
import ChevronRight from '../../assets/images/chevron-right.svg';
import EditWhiteboardName from '../../assets/images/editWhiteboardName.svg';
import {getTextMetrics} from '../../helpers/TextWrapHelpers';
import {isBuilderAiUser} from '../../helpers/Validation';
import {loggedInUser} from '../../services/AuthService';
import ExportOptionsBlock from './ExportOptionsBlock';
import {fabric} from 'fabric';
import DenyAccess from '../request/DenyAccess';
import HeaderCollaborators from './collaborators/HeaderCollaborators';
import { closeRequestAccessToast } from '../../helpers/ToastHelper';
import {importWhiteboardTeamData} from '../../helpers/importThirdPartyBoard/ImportThirdPartyBoard';
import {createNimaBoardPage, getOldWhiteboardCanvasInstance} from '../../helpers/importThirdPartyBoard/Utils';
import getToastIcon from '../../helpers/media/GetToastIcon';
import store from '../../redux/Store';
import ImportThirdPartyIconSVG from '../../assets/images/import-third-party-icon.svg';
import TrashIcon from '../../assets/images/trash3.svg';
import CopyIcon from '../../assets/images/copy2.svg';
import LayersIcon from '../../assets/images/layers/layers.svg';
import ChangingProgressProvider from '../boardListing/ChangingProgressProvider';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { deleteBoard, duplicateBoard } from '../../services/DataService';
import Eye from '../../assets/images/eye.svg';
import RequestEdit from '../request/RequestEdit';
import RequestEditApproval from '../request/RequestEditApproval';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import {NimaBoardImportAnalyzer} from '../../helpers/importThirdPartyBoard/Analyzer';


const DROPDOWN_ICONS = { 'copy': CopyIcon, 'delete': TrashIcon };
const Header = ({
    canvas: propsCanvas,
    userId,
    whiteBoardId,
    wbOwnerId,
    boardName: _boardName,
    whiteBoardSlugId,
    socketRef,
    isJoinedToSocketRoom,
    userAccessRef,
    location,
    shouldShowFeedBack,
    emitOnMouseDown,
    userIsCommenting,
    onMemberListUpdated,
    unreadCommentCount,
    handleToggleCommentDrawer,
    shouldHideComments,
    shouldHideResolvedComments,
    setSelectedCommentIcon
}) => {
    const navigate = useNavigate();
    const canvas = useCanvas();
    const dispatch = useDispatch();
    const canvasesPerPage = useContext(CanvasesContext);
    const modalRef = useRef();
    const deleteModalRef = useRef();
    const topBarLeftRef = useRef();
    const topBarRightRef = useRef();
    const [userToRemove, setUserToRemove] = useState({});
    const { users, isPublicAccess, isPublicLink, publicSharePermission, hasUpgradeRequest, whiteBoardOwnerId } = useSelector((state) => state?.board);
    // const migrationData = useSelector(state => state?.board?.migrationData);
    const {permission} = useSelector(state => state?.user);

    const [isLoadingInviteModal, setIsLoadingInviteModal] = useState(false);
    const [isThereUnreadComments, setIsThereUnreadComment] = useState(false);
    const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false);
    const [isRequestAccessDropdownOpen, setIsRequestAccessDropdownOpen] = useState(false);
    const [isBoardNameEditing, setIsBoardNameEditing] = useState(false);
    const [shouldCalculateLeftOffset, setShouldCalculateLeftOffset] = useState(-1);

    const deniedUserRef = useRef(null);
    const requestEditUserRef = useRef(null);
    const wbNameTextFieldRef = useRef(null);
    const fileInputRef = useRef();
    const fileInputLeaveTimeoutRef = useRef(null);
    const requestButtonTimeoutRef = useRef(null);
    const isCommentDrawerInitializedRef = useRef(null);
    const goToListing = () => {
        if(!isExternalProject()) {
            navigate('/boards');
        }
    }
    const studioBuildCardID = useSelector(state => state?.board?.studioBuildCardID);
    const appName = useSelector(state => state?.board?.appName);
    const pages = useSelector(state => state?.rightDrawer?.pages);
    const activePageId = useSelector(state => state?.rightDrawer?.activePage?.id);
    const flowchartStatus = useSelector(state => state?.board?.flowchartData?.status);

    const isUserHasAccess = userAccessRef.current !== USER_ROLES.notAllowed.id;

    const trackEventDownloadBoard = (downloadType) => {
        handleToggleCommentDrawer({ shouldShow: false });
        trackAction(
            SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_DOWNLOADED,
            SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_DOWNLOADED_DESCRIPTION,
            {
                [SEGMENT_ANALYTICS_CONSTANTS.USER_ID]: userId,
                [SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_ID]: whiteBoardId,
                [SEGMENT_ANALYTICS_CONSTANTS.USER_BROWSER]: getBrowserName(),
                [SEGMENT_ANALYTICS_CONSTANTS.USER_DEVICE]: getDeviceName(),
                [SEGMENT_ANALYTICS_CONSTANTS.DOWNLOAD_TYPE]: downloadType,
            }
        );
    }
    const trackEventShareBoard = () => {
        trackAction(
            SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_SHARED,
            SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_SHARED_DESCRIPTION,
            {
                [SEGMENT_ANALYTICS_CONSTANTS.USER_ID]: userId,
                [SEGMENT_ANALYTICS_CONSTANTS.WHITEBOARD_ID]: whiteBoardId,
                [SEGMENT_ANALYTICS_CONSTANTS.USER_BROWSER]: getBrowserName(),
                [SEGMENT_ANALYTICS_CONSTANTS.USER_DEVICE]: getDeviceName(),
            }
        );
    }

    const activeWindowState = useSelector(state => state?.modal?.activeWindow);
    const isCommentDrawerVisible = useSelector((state) => state.modal?.activeWindow === MODAL_WINDOWS.COMMENT_DRAWER);
    const isRightDrawerVisible = useSelector((state) => state.modal?.activeWindow === MODAL_WINDOWS.RIGHT_DRAWER);
    const isLayersWidgetActive = useSelector(state => state?.modal?.layersWidget);
    const showOverlay = activeWindowState === MODAL_WINDOWS.INVITE;
    const showDenyOverlay = activeWindowState === MODAL_WINDOWS.DENY_ACCESS;
    const showRequestAccessUpgradeOverlay = activeWindowState === MODAL_WINDOWS.REQUEST_EDIT;
    const showRequestEditApprovalOverlay = activeWindowState === MODAL_WINDOWS.REQUEST_EDIT_APPROVAL;
    const showRequestEditDenyOverlay = activeWindowState === MODAL_WINDOWS.REQUEST_EDIT_DENY;
    const setShowOverlay = useCallback((val) => {
        dispatch({
            type: 'modal/toggleInvite',
            payload: {
                shouldShow: val
            }
        })
    }, [dispatch]);

    const [boardName, setBoardName] = useState(_boardName),
        [lastBoardName, setLastBoardName] = useState(_boardName),
        [editBoard] = useState(false),
        closeOverlay = useOverlay(showOverlay, setShowOverlay, true, showDenyOverlay, showRequestAccessUpgradeOverlay, showRequestEditApprovalOverlay);


    const isUserOwner = useCallback(() => {
        return wbOwnerId && userId && wbOwnerId === userId;
    }, [wbOwnerId, userId]);

    const handleBoardNameChange = evt => {
        if (!isUserOwner()) {
            return;
        }
        setBoardName(evt.target.value);
        dispatch({type:'board/changeBoardDetails', payload: {boardName: evt.target.value}})
    };

    const openInviteModal = () => {
        closeRequestAccessToast();
        handleToggleCommentDrawer({ shouldShow: false });
        trackEventShareBoard();
        setShowOverlay(true);
    }

    const updateBoardName = () => {
        if (!isUserOwner()) {
            return;
        }
        setIsBoardNameEditing(false);
        let tempBName = boardName;
        if (boardName.trim() === '') {
            tempBName = lastBoardName;
            setBoardName(tempBName);
            setLastBoardName(tempBName);
        } else {
            setLastBoardName(tempBName);
        }
        const updateObj = {
            wbId: whiteBoardSlugId,
            name: tempBName
        };
        socketRef.current.emit('renameBoard', updateObj);
    }

    const handleFocusBoardName = () => {
        if (!isUserOwner()) {
            return;
        }
        setIsBoardNameEditing(true);
    }

    const handleCloseInvite = () => {
        closeOverlay();
        setShowOverlay(false);
    }


    const onImageLoad = (event) => {
        if (!isUserHasAccessToFeature('boardName', userAccessRef.current)) { return; }
        onImageUpload(event, canvas, eventEmitter, location, toast, {}, userAccessRef.current, activePageId);
    }

    const handleFlowchartActionClick = (flowchartActionType = FLOWCHART_MODAL_TYPES.IMPORT) => {
        handleToggleCommentDrawer({ shouldShow: false });
        dispatch({
            type: 'modal/setFlowchartModalDetails',
            payload: {
                showModal: true,
                modalType: flowchartActionType === FLOWCHART_MODAL_TYPES.IMPORT ? flowchartActionType : FLOWCHART_MODAL_TYPES.REMOVE
            }
        });
    }

    // Enabling flowchart feature is pending as of now
    // it can be enabled by setting isFlowchartEnabled to true
    
    const isFlowchartEnabled = true;
    const shouldShowFlowchartButton = (isFlowchartEnabled && studioBuildCardID && isMainPage(pages, activePageId)) ? true : false;

    /**
     * Removing user permission from board.
     * @param {number} userId
     */
    const removeUser = useCallback(
        (userId) => {
            setIsLoadingInviteModal(true);
            openInviteModal();
            removeUserAccess(whiteBoardSlugId, userId)
                .then(() => {
                    onMemberListUpdated({ userId, action: 'REMOVE' });
                })
                .finally(() => {
                    setIsLoadingInviteModal(false);
                    setUserToRemove({});
                });
        },
        [onMemberListUpdated, whiteBoardSlugId]
    );

    const focusWbName = () =>{
        wbNameTextFieldRef.current.focus();
    }
    
    const handleRequestButtonMouseEnter = () => {
        clearTimeout(requestButtonTimeoutRef.current); // Clear timeout if mouse re-enters
        setIsRequestAccessDropdownOpen(true);
    };
    
    const handleRequestButtonMouseLeave = () => {
        requestButtonTimeoutRef.current = setTimeout(() => {
            setIsRequestAccessDropdownOpen(false);
        }, 300);
    };

    const handleImportMenuMouseEnter = () => {
        clearTimeout(fileInputLeaveTimeoutRef.current); // Clear timeout if mouse re-enters
        setIsMoreDropdownOpen(true);
    };

    const handleImportMenuMouseLeave = () => {
        fileInputLeaveTimeoutRef.current = setTimeout(() => {
            setIsMoreDropdownOpen(false);
        }, 300);
    };

    const handleMouseOverOfCommentBlock = () => {
        setShouldCalculateLeftOffset(Math.random());
    }

    const handleCheckunreadCommentsExists = useCallback(() => {
        if (activeWindowState !== 'commentDrawer') {
            const unreadComments = canvas?.getObjects()?.filter(obj => obj?.type === 'comment' && obj?.unreadCommentCount);
            setIsThereUnreadComment(unreadComments?.length > 0)
        }
        else setIsThereUnreadComment(unreadCommentCount > 0)
    }, [canvas, activeWindowState, unreadCommentCount])

    const windowResizeHandler = useCallback(() => {
        setShouldCalculateLeftOffset(Math.random());
    }, []);

    /**
     * Memoized to active/authenticated user data.
     */
    const activeUser = useMemo(() => {
        const user = users.find((user) => user?.info?.userId === userId);
        return user ?? loggedInUser();
    }, [users, userId]);

    const isUserCanShare = useMemo(() => {
        if (!Array.isArray(users)) return false;

        // If user is not member of the board; user cannot share.
        const user = users.find(({ info }) => info.userId === userId);
        if (!user) return false;

        let allowToCommentAccess = false;
        if (user.info.permission === USER_ROLES.comment.id && studioBuildCardID) {
            allowToCommentAccess = true;
        } 
        
        if (!(user.info.permission === USER_ROLES.edit.id || allowToCommentAccess)) { return false; }
        if (user.joinedViaPublicLink) { return false; }
        
        /**
         * This checks added for the below scenario.
         * When board public access removed; in the meantime if user has board (given permission) access. (#2387723)
         */
        if (!isUserHasAccess) { return false; }
        return true;
    }, [userId, users, isUserHasAccess, studioBuildCardID]);

    /**
     * Checking that is the user has access to change other users permission.
     * @returns Boolean.
     */
    const isUserHasAccessToChangePermission = useCallback(() => {
        if (!activeUser) { return false;}
        if (!isBuilderAiUser(activeUser.email) && !isUserOwner()) { return false; }
        return isUserHasAccessToFeature('change_permission', userAccessRef?.current);

    }, [activeUser, userAccessRef, isUserOwner]);

    const handleThirdPartyImport = async () => {
        handleImportMenuMouseLeave()
        let createdPage;
        const changeMigrationStatus = (status, canvasInstance, migrationAnalytics) => {
            const isAnalyticsExist = migrationAnalytics && typeof migrationAnalytics === 'object'
            // instantly change the status
            const boardDetailsPayload = {
                nimaImportStatus: status
            }
            
            if (status === 'completed' && isAnalyticsExist) {
                boardDetailsPayload.nimaImportReport = migrationAnalytics;
            }
            
            dispatch({
                type: 'board/changeBoardDetails',
                payload: boardDetailsPayload
            })
            
            const socketData = {
                status,
                pageId: createdPage?.id // required for completed status
            }
            
            if (isAnalyticsExist) {
                socketData.migrationReport = migrationAnalytics
            }

            socketRef?.current?.emit('migration_status', socketData, (response) => {
                if (
                    status !== NIMA_IMPORT_STATUS.COMPLETED ||
                    response.status !== 'ok' ||
                    response?.emitData === null ||
                    typeof response?.emitData !== 'object' ||
                    Array.isArray(response?.emitData)
                ) {
                    return;
                }

                const {
                    migration_status_updatedAt: nimaImportUpdatedAt,
                    migration_status_updatedBy: nimaImportUpdatedBy,
                } = response.emitData;

                // if one of the values are undefined, do nothing
                if (!nimaImportUpdatedAt || !nimaImportUpdatedBy) {
                    return;
                }

                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {
                        nimaImportUpdatedBy,
                        nimaImportUpdatedAt
                    }
                })

                if (!canvasInstance) {
                    return;
                }

                // add log on activity log
                canvasInstance.fire('third-party-board-imported', {
                    nimaImportUpdatedBy,
                    nimaImportUpdatedAt
                })
            })
        }

        const deleteCreatedPageForNima = () => {
            try {
                socketRef?.current?.emit(
                    PAGE_SOCKET_EVENTS.PAGE_DELETED,
                    {
                        wbPageId: createdPage.wbPageId,
                        isDeleted: true,
                    },
                    () => {
                        dispatch({
                            type: 'rightDrawer/deletePage',
                            payload: {
                                wbPageId: createdPage.wbPageId
                            }
                        })
                    });
            } catch (err) {
                console.error('error while trying to delete the page', err)
            } finally {
                changeMigrationStatus(NIMA_IMPORT_STATUS.PENDING)
            }
        }

        const showErrorToast = (errorMsg) => {
            toast.error(errorMsg, {
                className: 'wb_toast',
                icon: getToastIcon('error')
            })
        }

        // const storeState = store?.getState();
        const userEmail = 'kirsti@builder.ai'
        if (!userEmail) {
            showErrorToast('an user is required for importing old whiteboard data')
        }
        const migrationUserList = users.map(user => {
            return {
                email: user.email,
                id: user.id,
                name: user.name,
                commentUsername: user.commentUsername,
                isOwner: user.id === whiteBoardOwnerId
            }
        })

        const guidResponse = {
            guid: 'd810d3b5-70cb-4107-88f1-4003c2644a5e'
        }
        if (!guidResponse?.guid) {
            showErrorToast(`couldn't find the guid for ${userEmail}`)
        }

        const nimaWbId = '55209e11-5001-47b9-b4ef-46646c64df8d'
        if (!nimaWbId) {
            showErrorToast("third party board couldn't found")
        }

        // show loader for all users
        changeMigrationStatus(NIMA_IMPORT_STATUS.IN_PROGRESS)
        const importAnalyzer = new NimaBoardImportAnalyzer();
        try {
            // create the page
            const pageData = await createNimaBoardPage(socketRef)
            createdPage = pageData;
            // after the page added, wait for the canvas instance of that page then import old data
            getOldWhiteboardCanvasInstance().then(async (newCanvasInstance) => {
                newCanvasInstance?.engine?.disableRendering();
                try {
                    await importWhiteboardTeamData(
                        newCanvasInstance,
                        {
                            nimaWbId,
                            guid: guidResponse.guid,
                            activePageId: pageData?.id,
                            wbSlugId: whiteBoardSlugId,
                            wbId: whiteBoardId,
                            userId: userId,
                            socket: socketRef.current,
                            shouldHideComments: shouldHideComments || false,
                            shouldHideResolvedComments: shouldHideResolvedComments || false,
                            setSelectedCommentIcon: setSelectedCommentIcon,
                            users: migrationUserList,
                            boardOwner: migrationUserList?.find(user => user?.isOwner),
                            analyzer: importAnalyzer,
                            addUsersToStore: (users) => {
                                if (!users || !Array.isArray(users) || !users?.length) {
                                    return
                                }
                                dispatch({
                                    type: 'board/addUsers',
                                    payload: users
                                })
                            }
                        }
                    );
                    eventEmitter?.fire(EMITTER_TYPES.FIT_TO_SCREEN);
                    changeMigrationStatus(NIMA_IMPORT_STATUS.COMPLETED, newCanvasInstance, importAnalyzer.getAnalysisReport())
                } catch (err) {
                    console.error(err)
                    toast.error(`couldn't import the data: ${err}`, {
                        className: 'wb_toast',
                        icon: getToastIcon('error')
                    })
                    deleteCreatedPageForNima()
                } finally {
                    newCanvasInstance?.engine?.enableRendering();
                }
            })
            // add created page to the store
            dispatch({
                type: 'rightDrawer/createNewPage',
                payload: {
                    id: pageData.id,
                    pageName: pageData.pageName,
                    wbPageId: pageData.wbPageId,
                },
            });

            dispatch({
                type: 'rightDrawer/changePage',
                payload: pageData.wbPageId
            })
        } catch (err) {
            changeMigrationStatus(NIMA_IMPORT_STATUS.PENDING)
            console.error(err)
            toast.error('unknown error occurred while importing the old data', {
                className: 'wb_toast',
                icon: getToastIcon('error')
            })
        }
    }
    
    const handleOpenRequestEditModal = () => {
        setIsRequestAccessDropdownOpen(false);
        dispatch({
            type: 'modal/toggleRequestAccessUpgradeModal',
            payload: {shouldShow:true}
        })
    }

    useEffect(() => {
        if (showOverlay && shouldShowFeedBack) {
            setShowOverlay(false);
        }
    }, [shouldShowFeedBack, showOverlay])

    useEffect(() => {
        if (!isUserCanShare && showOverlay) {
            setShowOverlay(false);
        }
    }, [isUserCanShare, showOverlay]);

    useEffect(() => {
        if (!isUserOwner()) { return; }
        const isBuilderUser = isBuilderAiUser(activeUser.email);

        if (isPublicLink && isPublicAccess) {
            const isPsUserAccess = publicSharePermission.users !== USER_ROLES.notAllowed.id;
            const isPsEmployeesAccess = publicSharePermission.employees !== USER_ROLES.notAllowed.id;

            if ((!isBuilderUser && isPsUserAccess === false)  || (isBuilderUser && !isPsUserAccess && !isPsEmployeesAccess)) {
                setShowOverlay(null);
                eventEmitter.fire(EMITTER_TYPES.DEAUTHORIZE_THE_USER);
            }
        }
    }, [isPublicAccess, isPublicLink, publicSharePermission.users, publicSharePermission.employees, activeUser?.email, isUserOwner, wbOwnerId, userId]);

    useEffect(() => {
        const handleDragOver = (e) => {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
        }
        const handleDrop = (e) => {
            e.stopPropagation();
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (files.length) {
                onImageUpload({target: {files}}, canvas, eventEmitter, location, toast, {}, userAccessRef.current, activePageId);
            }
        }

        const handlePaste = async (e) => {
            try {
                if (e.target) {
                    // if the target is input or textarea, do not copy the object in clipboard
                    if ((e.target?.localName === 'input' && e.target?.id !== 'text-copy-input') || e.target?.localName === 'textarea') {
                        return;
                    }
                }
                const clipboardItems = e.clipboardData?.files;
                if (clipboardItems.length) {
                    e.preventDefault();
                    await onImageUpload({target: {files: clipboardItems}}, canvas, eventEmitter, location, toast, {useMousePointer: true}, userAccessRef.current, activePageId);
                }
            } catch (err) {
                console.error('an error has occured while pasting image', err)
            }
        }
    
        if (canvas) {
            const selector = 'body';
            document.querySelector(selector).addEventListener('dragover', handleDragOver);
            document.querySelector(selector).addEventListener('drop', handleDrop);

            document.addEventListener('paste', handlePaste);
    
            return () => {
                document.querySelector(selector).removeEventListener('dragOver', handleDragOver);
                document.querySelector(selector).removeEventListener('drop', handleDrop);
                document.removeEventListener('paste', handlePaste);
            }
        }
    }, [canvas, emitOnMouseDown, location, eventEmitter]);

    // check flowchart status on the canvas to change flowchart status based on the user actions
    useEffect(() => {
        const getMainCanvas = () => {
            try {
                const mainPageId = getMainPageWbId(pages);
                if (mainPageId) {
                    const mainCanvas = canvasesPerPage?.canvases[mainPageId];
                    return mainCanvas;
                }
            } catch (err) {
                console.error('Error heppened getting main canvas', err);
            }
            return null;
        }

        const checkIfFlowchartItemExists = (canvas) => {
            try {
                return canvas.getObjects().some(obj => obj.flowchartProps);
            } catch (err) {
                console.error('Error happened', err);
            }
        }

        const flowchartItemAddedListener = debounce(() => {
            if (flowchartStatus === FLOWCHART_STATUS.ADDED || flowchartStatus === FLOWCHART_STATUS.IN_PROGRESS) return;
            try {
                const mainCanvas = getMainCanvas();
                if (mainCanvas) {
                    const flowchartItemExists = checkIfFlowchartItemExists(mainCanvas);
                    if (flowchartItemExists) {
                        dispatch({
                            type: 'board/changeBoardDetails',
                            payload: {
                                userFlowDiagram_status: FLOWCHART_STATUS.COMPLETED
                            }
                        });
                    }
                }
            } catch (err) {
                console.error('Error happened', err);
            }
        }, 300);

        const flowchartItemRemovedListener = debounce(() => {
            if (flowchartStatus === FLOWCHART_STATUS.REMOVED) return;
            try {
                const mainCanvas = getMainCanvas();
                if (mainCanvas) {
                    const flowchartItemExists = checkIfFlowchartItemExists(mainCanvas);
                    if (!flowchartItemExists) {
                        dispatch({
                            type: 'board/changeBoardDetails',
                            payload: {
                                userFlowDiagram_status: FLOWCHART_STATUS.REMOVED
                            }
                        });
                        try {
                            socketRef.current.emit('ufd_status', {
                                status: FLOWCHART_STATUS.REMOVED,
                            }); 
                        } catch (err) {
                            console.error('an error has occured while emitting ufd_status', err);
                        }
                    }
                }
            } catch (err) {
                console.error('Error happened', err);
            }
        }, 300);

        const handleUnreadCommentsExists = () => {
            if (activeWindowState !== 'commentDrawer') setIsThereUnreadComment(true);
        }


        eventEmitter.on(EMITTER_TYPES.FLOWCHART_ITEM_ADDED, flowchartItemAddedListener);
        eventEmitter.on(EMITTER_TYPES.FLOWCHART_ITEM_REMOVED, flowchartItemRemovedListener);
        eventEmitter.on(EMITTER_TYPES.SET_UNREAD_COMMENT_EXISTS, handleUnreadCommentsExists)
        eventEmitter.on(EMITTER_TYPES.CHECK_UNREAD_COMMENTS, handleCheckunreadCommentsExists)

        return () => {
            eventEmitter.off(EMITTER_TYPES.FLOWCHART_ITEM_ADDED, flowchartItemAddedListener);
            eventEmitter.off(EMITTER_TYPES.FLOWCHART_ITEM_REMOVED, flowchartItemRemovedListener);
            eventEmitter.off(EMITTER_TYPES.SET_UNREAD_COMMENT_EXISTS, handleUnreadCommentsExists)
            eventEmitter.off(EMITTER_TYPES.CHECK_UNREAD_COMMENTS, handleCheckunreadCommentsExists)
        }

    }, [pages, canvasesPerPage, dispatch, flowchartStatus, canvas, handleCheckunreadCommentsExists]);

    useEffect(() => {
        const getBoardNameTextWidth = (text, inputEl) => {
            try {
                const inputStyles = window.getComputedStyle(inputEl)
                const font = inputStyles.getPropertyValue('font-weight') + ' ' + inputStyles.getPropertyValue('font-size') + ' ' +
                    inputStyles.getPropertyValue('font-family')

                const metrics = getTextMetrics(text, font);
                return metrics.width;
            } catch (err) {
                console.error(err)
            }
            return 0;
        }

        if (wbNameTextFieldRef?.current) {
            if (boardName === '') {
                wbNameTextFieldRef.current.style.width = '1px';
            } else {
                const textWidth = getBoardNameTextWidth(boardName, wbNameTextFieldRef.current)
                wbNameTextFieldRef.current.style.width = textWidth + 'px';
            }
        }
    }, [boardName])

    useEffect(() => {
        handleCheckunreadCommentsExists()
    }, [activePageId, handleCheckunreadCommentsExists])
    
    const isBoardNameEditingAllowed = isUserOwner() &&
        isUserHasAccessToFeature('boardname', userAccessRef.current)

    useEffect(() => {
        if (Object.keys(userToRemove).length !== 0) modalRef.current?.openModal();
    }, [userToRemove]);

    useEffect(() => {
        if (propsCanvas) {
            setTimeout(() => {
                const newWidth = isCommentDrawerVisible ? window.innerWidth - COMMENT_DRAWER_WIDTH : window.innerWidth;
                const vpt = [...propsCanvas.viewportTransform];
                propsCanvas.setWidth(newWidth);

                if (isCommentDrawerVisible) {
                    vpt[4] -= COMMENT_DRAWER_WIDTH;
                    isCommentDrawerInitializedRef.current = true;
                } else if (isCommentDrawerInitializedRef.current === true) {
                    vpt[4] += COMMENT_DRAWER_WIDTH;
                }

                propsCanvas.setViewportTransform(vpt);
            }, isRightDrawerVisible ? ANIMATION_DURATIONS.RIGHT_DRAWER_VISIBILITY : ANIMATION_DURATIONS.COMMENT_DRAWER_VISIBILITY);
        }
    }, [isCommentDrawerVisible, propsCanvas]);

    useEffect(() => {
        setTimeout(() => {
            const topbarRightOffsetLeft = topBarRightRef?.current?.getBoundingClientRect()?.left;
            const LEFT_PADDING = 30;
            const MARGIN_BETWEEN_TOPBAR = 50;
            topBarLeftRef.current.style.maxWidth = `${topbarRightOffsetLeft - LEFT_PADDING - MARGIN_BETWEEN_TOPBAR}px`;
        }, 300);

        // 300 Timeout added because of css animatiom.
    }, [activeWindowState, shouldCalculateLeftOffset]);

    useEffect(() => {
        window.addEventListener('resize', windowResizeHandler);

        return () => {
            window.removeEventListener('resize', windowResizeHandler);
        }
    }, [windowResizeHandler]);
    
    useEffect(() => {
        if(!isJoinedToSocketRoom) return;
        socketRef.current.on(SOCKET_EVENT.BOARD_NAME_UPDATED, (data) => {
            dispatch({
                type: 'board/changeBoardDetails',
                payload: { boardName: data.name }
            });
            setBoardName(data.name);
        });
    },[socketRef, isJoinedToSocketRoom])

    const goToBoard = (boardId) => {
        // if whiteboard is used in another products using iframe
        if(appName !== APP_NAMES.APEIROS){
            toast.dismiss(`duplicate-success-${whiteBoardId}`);
            window.open(window.location.origin + '/board/' + boardId, '_blank');
        }else{
            window.location.href = '/board/' + boardId;
        }

    }

    const handleDropdownItemClick = async ({ id }) => {
        await toast.dismiss();
        let isError = false;
        if (id === 'copy') {
            toast.info(
                <span className="duplicate-board_toast__text">
                    <div className="duplicate-board_toast__loader">
                        <ChangingProgressProvider values={[0, 100]}>
                            {(percentage) => (
                                <CircularProgressbar
                                    value={percentage}
                                    styles={buildStyles({
                                        pathTransition: percentage === 0 ? 'none' : 'stroke-dashoffset 1.2s ease 0s',
                                        rotation: 0.5 + (1 - percentage / 100) / 2,
                                        pathColor: '#6200EA',
                                        trailColor: '#B388FF4D',
                                        strokeWidth: 20
                                    })}
                                />
                            )}
                        </ChangingProgressProvider>
                    </div>
                    <span className="duplicate-board_toast__text--main">Duplicating whiteboard</span>
                </span>, {
                    icon: false,
                    autoClose: false,
                    className: 'wb_toast wb_toast__duplicate-board',
                    draggable: false,
                    toastId: `duplicate-loading-${whiteBoardId}`
                })
            try {
                const response = await duplicateBoard(whiteBoardId)

                if (response?.success) {
                    toast.dismiss(`duplicate-loading-${whiteBoardId}`);
                    toast.info(
                        <span className='duplicate-board_toast__text'>
                            <span className='duplicate-board_toast__text--main'>Whiteboard duplicated successfully</span>
                            <span
                                onClick={() => {
                                    store.dispatch({ type: 'layers/clearState' });
                                    goToBoard(response.data.wbId);
                                }}
                                className='duplicate-board_toast__button toast_btn btn-width'
                            >
                                Open whiteboard
                            </span>
                        </span>,
                        {
                            icon: false,
                            autoClose: false,
                            className: 'wb_toast wb_toast__duplicate-board',
                            draggable: false,
                            toastId: `duplicate-success-${whiteBoardId}`,
                        }
                    );
                }
            }
            catch (err) {
                isError = true;
            }
            finally {
                if (isError) {
                    toast.error('An error has occurred while duplicating this board. Please try again later!', {
                        icon: getToastIcon('error'),
                        className: 'wb_toast wb_toast__duplicate-board',
                        toastId: `duplicate-error-${whiteBoardId}`
                    });
                }
            }
        }
        else {
            deleteModalRef.current?.openModal();
        }
    }

    const handleDeleteWhiteboard = async () => {
        deleteModalRef.current?.closeModal();
        await toast.dismiss();
        let isError = false;
        try {
            const response = await deleteBoard(whiteBoardId);
            if (response?.success) {
                navigate('/boards');
                setTimeout(()=>{
                    toast.success(`"${boardName}" deleted.`, {
                        icon: getToastIcon('success'),
                        className: 'wb_toast wb_toast__delete-board',
                        autoClose: true
                    });
                }, 100);
            }
        }
        catch (err) {
            isError = true;
        }
        finally {
            if (isError) {
                toast.error('An error has occurred while deleting this board. Please try again later!', {
                    icon: getToastIcon('error'),
                    className: 'wb_toast wb_toast__duplicate-board',
                    toastId: `delete-error-${whiteBoardId}`
                });
            }
        }
    }
    
    const handleLayersBtnClick = () => {
        if (isLayersWidgetActive) {
            eventEmitter.fire(EMITTER_TYPES.RESET_LAYERS_WIDGET_POSITION);
        } else {
            dispatch({
                type: 'modal/toggleLayersWidget',
                payload: true
            })
        }
    }


    return (
        <>
            <div className='topBar__left' ref={topBarLeftRef}>

                <div
                    className={clsx('logo', { '--externalProject': isExternalProject() })}
                    onClick={goToListing}
                >
                    <img alt="Logo" src={builderLogo} style={{height: '20px'}}/>
                </div>
                {
                    isBoardNameEditingAllowed ? (
                        <div className={clsx('nameWrapper', {
                            '--allowed': isBoardNameEditingAllowed,
                        })}>
                            <div
                                className={clsx('boardName__wrapper', {
                                    '--editing': isBoardNameEditing
                                })}>
                                <input
                                    className={clsx('boardName', { 'show': editBoard, 'hide': !editBoard })}
                                    id="boardNameId"
                                    maxLength={50}
                                    onBlur={updateBoardName}
                                    onChange={handleBoardNameChange}
                                    onFocus={handleFocusBoardName}
                                    readOnly={!isUserOwner() || !(isUserHasAccessToFeature('boardName', userAccessRef.current))}
                                    ref={wbNameTextFieldRef}
                                    type="text"
                                    value={boardName}
                                />
                            </div>
                            {
                                (isBoardNameEditingAllowed && !isBoardNameEditing) ? <img alt="export board" className="iconEdit" onClick={focusWbName} src={EditWhiteboardName} /> : null
                            }
                        </div>
                    ) : (
                        <div className = {clsx('nameWrapper', {
                            '--not-allowed': !isBoardNameEditingAllowed,
                        })}>
                            <span className="boardNameText">
                                {boardName}
                            </span>
                        </div>
                    )
                }
                {
                    (permission !== 'edit' && studioBuildCardID === null) ? (
                        hasUpgradeRequest ? <div className='request-access-pending'>{isPublicAccess ? 'Request' : 'Upgrade'} access pending</div> : <div
                            className={clsx('request-access-button-container', {active: isRequestAccessDropdownOpen})}
                            onMouseEnter={handleRequestButtonMouseEnter}
                            onMouseLeave={handleRequestButtonMouseLeave}
                        >
                            <div
                                className={clsx('request-access-button', {comment: permission === 'comment'})}
                            >
                                <img
                                    alt="Toggle Password Visibility"
                                    src={Eye}
                                />
                                <div>{permission === 'comment' ? 'Comment' : 'View'} only</div>
                            </div>
                            <div className="request-access-upgrade-button-wrapper">
                                <div className="request-access-upgrade-title">Request owner to {isPublicAccess ? 'upgrade' : ''} access</div>
                                <div onClick={handleOpenRequestEditModal} className="request-access-upgrade-button">
                                    Request access
                                </div>
                            </div>
                        </div>
                    ) : null
                }

            </div>

            {(userAccessRef.current !== USER_ROLES.notAllowed.id) ? <div ref={topBarRightRef} className={clsx('topBar__right', {
                '--searchBarExpanded': activeWindowState === MODAL_WINDOWS.SEARCH,
            })}>
                <HeaderCollaborators />
                <TooltipWrapper className='layers-button-tooltip' placement='bottom-end' text='Layers'>
                    <div className={clsx('layersBlock', { active: isLayersWidgetActive })}>
                        <button
                            className='layersBtn'
                            onClick={handleLayersBtnClick}
                            type='button'
                            aria-labelledby='BuildcardIcon'
                        >
                            <img src={LayersIcon} alt='Layers' aria-label='Open layers widget' id='layersBtnIcon' />
                        </button>
                    </div>
                </TooltipWrapper>
                <div className='topBarHeaderSearch'>
                    <HeaderSearch handleToggleCommentDrawer={handleToggleCommentDrawer} canvas={canvas} />

                </div>
                <div
                    className={clsx('moreOptions', { active: isMoreDropdownOpen })}
                    data-tutorial='import-export'
                    onMouseEnter={handleImportMenuMouseEnter}
                    onMouseLeave={handleImportMenuMouseLeave}
                >
                    <div className="more" title="More"><em className="icon-canvas-redesign-menu" /></div>
                    <div className="moreOptionsBlock">
                        <div className="moreOptionsInner">
                            <div
                                className={clsx('import', { disabled: !isUserHasAccessToFeature('image_upload', userAccessRef.current) })}
                                onClick={() => {
                                    handleToggleCommentDrawer({ shouldShow: false });
                                    setIsMoreDropdownOpen(false);
                                    fileInputRef.current.click()
                                }}
                            >
                                <em className="icon-canvas-redesign-import-images" />
                                <span>Import images</span>
                                <input
                                    accept="image/*"
                                    className="image2"
                                    disabled={!isUserHasAccessToFeature('image_upload', userAccessRef.current)}
                                    multiple
                                    onChange={onImageLoad}
                                    ref={fileInputRef}
                                    type="file"
                                />
                            </div>

                            <div className={clsx('export', { disabled: !isUserHasAccessToFeature('export_board', userAccessRef.current) })}>
                                <em className="icon-canvas-redesign-export-board" />
                                <span>Export board</span>
                                <img alt="See export options" height={12} src={ChevronRight} style={{marginLeft:'40px'}} width={12}/>
                                <ExportOptionsBlock
                                    canvas={canvas}
                                    onDownloadCanvas={onDownloadCanvas}
                                    onDownloadSVG={onDownloadSVG}
                                    trackEventDownloadBoard={trackEventDownloadBoard}
                                />
                            </div>

                            {
                                (shouldShowFlowchartButton
                                    && isUserHasAccessToFeature('import_flowchart', userAccessRef.current)) ? <div className={clsx('userFlowItem')} onClick={() => handleFlowchartActionClick(FLOWCHART_MODAL_TYPES.IMPORT) } title="Import user flows">
                                        <em className="icon-canvas-redesign-import-user-flow" />
                                        <span>Import user flows</span>
                                    </div> : null
                            }
                            {
                                (
                                    // migrationData?.nimaWbId &&
                                    activeUser?.email !== ''
                                    // isBuilderAiUser(activeUser.email) &&
                                    // migrationData.nimaImportStatus !== NIMA_IMPORT_STATUS.COMPLETED &&
                                    // migrationData.nimaImportStatus !== NIMA_IMPORT_STATUS.IN_PROGRESS
                                ) ? (
                                    <div className="headerMoreButtonItem importThirdPartyBtn" onClick={() => handleThirdPartyImport()}>
                                        <img src={ImportThirdPartyIconSVG} alt="Import data from old whiteboard" />
                                        <span>Import data from old whiteboard</span>
                                    </div>
                                    ) : null
                            }
                            {
                                BOARD_ITEM_DROPDOWN_MENU.map((item) => {
                                    if ((userId !== wbOwnerId || studioBuildCardID || appName !== APP_NAMES.APEIROS) && !item.public) return;
                                    return (
                                        <div key={item.id} onClick={() => handleDropdownItemClick({ id: item.id })} className="board-item-menu">
                                            <img src={DROPDOWN_ICONS[item.id]} alt={item.label} />
                                            <span >{item.label}</span>
                                        </div>
                                    )
                                })
                            }


                            {/* <div className="boardSetting panel" title="Board Setting"><span>Board settings</span><em className="icon-setting"></em>
                            <div className="boardSettingBlock">
                                <div className="boardSettingBlockInner">
                                    <BoardSetting boardBackgroundClass={boardBackgroundClass}/>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="commentBlock" data-tutorial="comment" onMouseOver={handleMouseOverOfCommentBlock}>
                    <button
                        className={clsx(
                            'commentBtn',
                            { active: userIsCommenting }
                        )}
                        onClick={() => handleToggleCommentDrawer({ shouldShow: true })}
                        type="button"
                    >
                        <img alt="Comments" src={ (!isThereUnreadComments) ? CommentIconBubble : CommentIconBubbleUnread} />
                        <div className ="comment-title">Comment</div>
                    </button>
                </div>
                {
                    !isEnterpriseProject() ? <div className="shareBlock">
                        <button
                            className="shareBtn"
                            disabled={!isUserCanShare}
                            onClick={openInviteModal}
                            type="button"
                        >
                            <em className="icon-canvas-redesign-share" />
                            <div className="share-title">Share</div>
                        </button>
                    </div> : null
                }
                <div className="helpOption"><HelpButton handleToggleCommentDrawer={handleToggleCommentDrawer} activeWindowState={activeWindowState} canvas={canvas} userId={userId} />

                </div>
            </div> : null}
            {showRequestAccessUpgradeOverlay ? <RequestEdit /> : null}
            {(isUserCanShare) ? <>
                {showOverlay ? <Invite
                    activeUser={activeUser}
                    handleClose={handleCloseInvite}
                    isLoading={isLoadingInviteModal}
                    isUserHasAccessToChangePermission={isUserHasAccessToChangePermission}
                    onMemberListUpdated={onMemberListUpdated}
                    setUserToRemove={setUserToRemove}
                    setShowOverlay={setShowOverlay}
                    socketRef={socketRef}
                    deniedUserRef={deniedUserRef}
                    requestEditUserRef={requestEditUserRef}
                /> : null}
                {showRequestEditApprovalOverlay ? <RequestEditApproval deniedUserRef={deniedUserRef} requestEditUserRef={requestEditUserRef} /> : null}
                {(showDenyOverlay || showRequestEditDenyOverlay) ? <DenyAccess isRequestEditDeny={showRequestEditDenyOverlay} deniedUserRef={deniedUserRef} handleClose={handleCloseInvite} /> : null}

                {Object.keys(userToRemove).length !== 0 ? <Modal
                    actions={[
                        <button
                            key="cancel"
                            id="removeAccessCancelBtn"
                            onClick={() => {
                                modalRef.current?.closeModal();
                                openInviteModal();
                            }}
                            type="button"
                        >
                            Cancel
                        </button>,
                        <button
                            key="remove"
                            className="delete"
                            id="removeAccessRemoveBtn"
                            onClick={() => {
                                modalRef.current.closeModal();
                                removeUser(userToRemove.id);
                            }}
                            type="button"
                        >
                            Remove
                        </button>,
                    ]}
                    description={`Are you sure you want to remove ${userToRemove.name}? She/He won't be able to access this board anymore.`}
                    onCloseClick={() => {
                        openInviteModal();
                    }}
                    ref={modalRef}
                    title="Are you sure?"
                /> : null}
            </> : null}
            <Modal
                ref={deleteModalRef}
                title={`Are you sure you want to delete ${boardName}?`}
                description={<span>This whiteboard will be moved to <span>Trash</span> section</span>}
                className="delete-board-modal"
                actions={[
                    <button
                        key="cancel"
                        type="button"
                        id="boardsCancelBtn"
                        onClick={() => {
                            deleteModalRef?.current?.closeModal();
                        }}
                    >
                        Cancel
                    </button>,
                    <button key="delete" type="button" id="boardsDeleteBtn" onClick={handleDeleteWhiteboard}>
                        Delete
                    </button>
                ]}
            />
        </>
    );
}

Header.propTypes = {
    canvas: PropTypes.instanceOf(fabric.Canvas),
    userId: PropTypes.number,
    whiteBoardId: PropTypes.number,
    wbOwnerId: PropTypes.number,
    boardName: PropTypes.string,
    whiteBoardSlugId: PropTypes.string,
    socketRef: PropTypes.shape({
        current: PropTypes.object
    }),
    isJoinedToSocketRoom: PropTypes.bool,
    userAccessRef: PropTypes.shape({
        current: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
    }).isRequired,
    location: PropTypes.object,
    shouldShowFeedBack: PropTypes.bool,
    emitOnMouseDown: PropTypes.func,
    userIsCommenting: PropTypes.bool,
    onMemberListUpdated: PropTypes.func.isRequired,
    unreadCommentCount: PropTypes.bool,
    handleToggleCommentDrawer: PropTypes.func.isRequired,
    shouldHideComments: PropTypes.bool,
    shouldHideResolvedComments: PropTypes.bool,
    setSelectedCommentIcon: PropTypes.func.isRequired
}

export default Header;
