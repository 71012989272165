import { SHAPE_DEFAULTS } from '../Constant';
import store from '../../redux/Store';
import { getBrightnessFromRGBA } from '../ColorHelper';

const OWNER_SECTION_PADDING = 8;
const OWNER_SECTION_FONT_SIZE = 10;
const ICON_PROPS = {
    width: 6.5,
    height: 7.5,
    marginLeft: 4.75,
    marginRight: 4.75
};
export default class StickyOwner {
    constructor(ctx, stickyNote) {    
        this.stickyNote = stickyNote;
        this.fontSize = OWNER_SECTION_FONT_SIZE;
        this.padding = OWNER_SECTION_PADDING;
        this.width = stickyNote.width;
        this.height = SHAPE_DEFAULTS.STICKY_NOTE_OWNER_SECTION_HEIGHT;
        this.left = -this.width / 2 + ICON_PROPS.marginLeft;
        this.top = (stickyNote.height / 2) - this.height;
        this.textColor = this.getTextColor();
        this.context = ctx;
        this.iconProps = ICON_PROPS;
    }

    static isOwnerNameOverflowed(text, objWidth) {
        const canvasEl = document.createElement('canvas');
        const ctx = canvasEl.getContext('2d');

        ctx.font = `${OWNER_SECTION_FONT_SIZE}px ${SHAPE_DEFAULTS.FONT_FAMILY}`;

        const iconArea = OWNER_SECTION_PADDING + ICON_PROPS.width + ICON_PROPS.marginRight;
        const textWidthArea = objWidth - iconArea - OWNER_SECTION_PADDING;
        let textWidth = ctx.measureText(text).width;
        
        if (textWidth > textWidthArea) {
            return true;
        }

        return false;
    }

    draw() {
        this.drawIcon();
        this.drawText();
    }

    drawIcon() {
        const imgPath1 = 'M4 4.75C4.9665 4.75 5.75 3.9665 5.75 3C5.75 2.0335 4.9665 1.25 4 1.25C3.0335 1.25 2.25 2.0335 2.25 3C2.25 3.9665 3.0335 4.75 4 4.75Z';
        const imgPath2 = 'M0.75 8.75C0.75 6.95507 2.20507 5.5 4 5.5C5.79493 5.5 7.25 6.95507 7.25 8.75';

        const path1 = new Path2D(imgPath1);
        const path2 = new Path2D(imgPath2);

        // Combine paths
        const combinedPath = new Path2D();
        combinedPath.addPath(path1);
        combinedPath.addPath(path2);

        // Translate the combined path to the specified (x, y) position
        this.context.save(); // Save the current state
        this.context.translate(
            this.left + this.padding,
            this.top + (this.padding) + (this.iconProps.height / 2),
        ); // Translate the context to (x, y)
        this.context.strokeStyle = this.textColor;
        this.context.stroke(combinedPath);
        this.context.restore(); // Restore the original state
    }

    getOwnerName() {
        if (this.stickyNote?.ownerName) {
            return this.stickyNote?.ownerName;
        }

        const { board: { users } } = store.getState()
        if (!Array.isArray(users)) return '';

        const user = users.find((user) => user.id === this.stickyNote?.createdBy);
        return user?.name ?? '';
    }

    getText(textWidthArea) {
        let text = this.getOwnerName();
        const ellipsis = '...';
        const ellipsisWidth = this.context.measureText(ellipsis).width;
        let textWidth = this.context.measureText(text).width;

        if (textWidth > textWidthArea) {
            while (textWidth >= textWidthArea - ellipsisWidth) {
                if (textWidthArea - ellipsisWidth < 0) {
                    return ''
                }
                text = text.slice(0, -1);
                textWidth = this.context.measureText(text).width;
            }
            text += ellipsis;
        }

        return text;
    }

    drawText() {
        this.context.save();
        this.context.fillStyle = this.textColor;
        this.context.font = `${this.fontSize}px ${SHAPE_DEFAULTS.FONT_FAMILY}`;

        const iconArea = this.padding + this.iconProps.width + this.iconProps.marginRight;
        const textWidthArea = this.width - iconArea - this.padding;

        const text = this.getText(textWidthArea);

        this.context.fillText(
            text,
            this.left + iconArea,
            this.top + (this.padding * 2) + (this.iconProps.height / 2),
            this.width
        );
        this.context.restore();
    }

    getTextColor() {
        let textColor = SHAPE_DEFAULTS.TEXT_COLOR;
        const rect = this.stickyNote?.getObjects()?.find((obj) => obj.type === 'rect');
        const shapeBg = rect.fill ?? SHAPE_DEFAULTS.STICKY_NOTE_BACKGROUND;
        
        if (shapeBg !== 'transparent') {
            const brightness = getBrightnessFromRGBA(shapeBg);

            if (brightness < 128) {
                textColor = `rgba(255, 255, 255, 1)`;
            }
        }

        return textColor || SHAPE_DEFAULTS.TEXT_COLOR;
    }

    getLastCharStyle(textbox) {
        // Get the text length
        const textLength = textbox.text.length;

        // Find the line and character index for the last character
        const lastCharIndex = textLength - 1;
        const lines = textbox.text.split('\n');

        // Calculate the line and character position of the last character
        let lineIndex = 0;
        let charIndexInLine = lastCharIndex;

        for (let i = 0; i < lines.length; i++) {
            if (charIndexInLine < lines[i].length) {
                lineIndex = i;
                break;
            }
            charIndexInLine -= (lines[i].length + 1);  // +1 for the '\n' character
        }

        return { lineIndex, charIndexInLine }
    }
}